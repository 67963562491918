<template><div class="cash">
	<div class="box"><div class="p16">
		<div class="pb16 flex jc_b ai_c">
			<el-button type="primary" class="ssbtn" style="width: 6.75rem;" @click="plczDialog = true">批量操作</el-button>
			<div class="flex jc_end f14 col333 ai_c">
				<span class="mr10">数据类型：</span>
				<el-select v-model="select.dataType" clearable  style="width: 6.875rem;" class="xzselect"  placeholder="全部">
					<el-option v-for="item in selectOption.dataOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<span class="mr10 ml24">影院类型：</span>
				<el-select v-model="select.cinameType" clearable  style="width: 6.875rem;" class="xzselect" placeholder="全部">
					<el-option v-for="item in selectOption.cinameOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<span class="mr10 ml24">搜索类型：</span>
				<el-select v-model="select.searchType" clearable  style="width: 6.875rem;" class="xzselect">
					<el-option v-for="item in selectOption.searchOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-input v-model="select.valSerch" clearable  placeholder="在输入前请先选择搜索类型" style="width: 19.875rem;" class="ml15 mr20 xzinput"></el-input>

				<el-button type="primary" class="ssbtn" style="width: 5.375rem; margin: 0 0 0 1.25rem!important;" @click="searchBtn">搜 索</el-button>
			</div>
		</div><!---->
		<div class="table">
			<el-table :data="tableData" border style="width: 100%;">
				<el-table-column prop="valtable1" label="影院" width="270">
					<template slot-scope="scope">            
						<div>{{scope.row.nm}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="cname" label="城市" width="85">
					<template slot-scope="scope">            
						<div>{{scope.row.cname}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="addr" label="地址" width="385">
					<template slot-scope="scope">            
						<div>{{scope.row.addr}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="in20" label="20以内" >
					<template slot-scope="scope">            
						<div>{{scope.row.in20}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="from20to30" label="20-30" >
					<template slot-scope="scope">            
						<div>{{scope.row.from20to30}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="from30to40" label="30-40" >
					<template slot-scope="scope">            
						<div>{{scope.row.from30to40}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="from40to50" label="40-50" >
					<template slot-scope="scope">            
						<div>{{scope.row.from40to50}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="from50to60" label="50-60" >
					<template slot-scope="scope">            
						<div>{{scope.row.from50to60}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="from60to70" label="60-70" >
					<template slot-scope="scope">            
						<div>{{scope.row.from60to70}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="from70to80" label="70-80" >
					<template slot-scope="scope">            
						<div>{{scope.row.from70to80}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="from80to90" label="80-90" >
					<template slot-scope="scope">            
						<div>{{scope.row.from80to90}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="out90" label="90以上" >
					<template slot-scope="scope">            
						<div>{{scope.row.out90}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="is_old" label="数据类型">
					<template slot-scope="scope">            
						<div v-if="!scope.row.is_old">新数据</div>
						<div v-else-if="scope.row.is_old == 1">老数据</div>
						<!--<div style="color:#F74D4D ;">新数据</div>-->
					 </template>    
				</el-table-column>
				<el-table-column prop="tp" label="影院类型" >
					<template slot-scope="scope">            
						<div v-if="scope.row.tp">连锁</div>
						<div v-else-if="scope.row.tp == 0">非连锁</div>
					 </template>
				</el-table-column>
				<el-table-column label="操作" fixed="right">
					<template slot-scope="scope">            
						<el-button class="qxbtn mr10" @click="editBtn(scope.row)">编辑</el-button>   
					 </template>
				</el-table-column>
			</el-table>
		</div><!--table end-->
		<div class="page pt16">
			<el-pagination
				background
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-size="pageSize"
				:total="total">	
			</el-pagination>
		</div>
	</div></div><!--box end-->
	<el-dialog id="plcz" class="dialogHeaderno" width="986px" :visible.sync="plczDialog">
		<div class="qxDialogHeader flex-column jc_c"><div class="flex jc_b ai_c">
			<span class="f16 colfff">修改操作</span>
			<i class="iconfont iconguanbi colfff f18" @click="plczDialog = false"></i>
			
		</div></div>
		<div class="xgczMid"><el-form>
			<div class="xgczMidm">
				<span class="qxmidmtxt">影院(条件)</span>
				<el-form-item>
					<el-input v-model="batch.nm" placeholder=""  auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<div class="xgczMidm mb35 mt40">
				<span class="qxmidmtxt">影院类型</span>
				<el-form-item>
					<el-radio-group v-model="batch.tp" class="xzradio2 xzradio2b flex jc_s ai_c">
					    <el-radio :label="2">所有</el-radio>
					    <el-radio :label="0" style="margin:0 40px 0 30px">非连锁</el-radio>
						<el-radio :label="1">连锁</el-radio>
					  </el-radio-group>
				</el-form-item>
			</div>
			<div class="xgczMidm">
				<div class="flex jc_b">
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">20以内</span>
						<el-form-item>
							<el-input-number v-model="batch.in20"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">20-30</span>
						<el-form-item>
							<el-input-number  v-model="batch.from20to30" style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">30-40</span>
						<el-form-item>
							<el-input-number v-model="batch.from30to40"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
				</div><!---->
				<div class="flex jc_b pv20">
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">40-50</span>
						<el-form-item>
							<el-input-number v-model="batch.from40to50"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">50-60</span>
						<el-form-item>
							<el-input-number v-model="batch.from50to60"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">60-70</span>
						<el-form-item>
							<el-input-number v-model="batch.from60to70"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
				</div>
				<div class="flex jc_b pb15">
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">70-80</span>
						<el-form-item>
							<el-input-number  v-model="batch.from70to80" style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">80-90</span>
						<el-form-item>
							<el-input-number v-model="batch.from80to90"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">90以上</span>
						<el-form-item>
							<el-input-number v-model="batch.out90"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
				</div><!---->
			</div><!--xgczMidm end-->
			<div class="flex jc_c ai_c pv45">
				<el-button type="primary" class="ssbtn" style="width: 5.375rem; margin-right: 5rem" @click="batchConfirm">确认</el-button>
				<el-button class="cancelbtn" style="width: 5.375rem;" @click="plczDialog = false">取消</el-button>
			</div>
		</el-form></div><!--qxmid end-->
	</el-dialog>
	<el-dialog id="bjxg" class="dialogHeaderno" width="986px" :visible.sync="bjxgDialog">
		<div class="qxDialogHeader flex-column jc_c"><div class="flex jc_b ai_c">
			<span class="f16 colfff">修改折扣</span>
			<i class="iconfont iconguanbi colfff f18" @click="bjxgDialog = false"></i>
		</div></div>
		<div class="xgczMid"><el-form>
			<div class="xgczMidm xgczMidm2 mb20">
				<span class="qxmidmtxt">影院</span>
				<el-form-item style="width: 496px;">
					<el-input placeholder="" v-model="item.nm"  :disabled="true"  auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<div class="xgczMidm xgczMidm2 mb20">
				<span class="qxmidmtxt">城市</span>
				<el-form-item style="width: 496px;">
					<el-input placeholder="" v-model="item.cname"  :disabled="true" auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<div class="xgczMidm xgczMidm2">
				<span class="qxmidmtxt">地址</span>
				<el-form-item>
					<el-input placeholder="" v-model="item.addr"  :disabled="true" auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<div class="xgczMidm mb35 mt40">
				<span class="qxmidmtxt">影院类型</span>
				<el-form-item>
					<el-radio-group v-model="item.tp" class="xzradio2 xzradio2b flex jc_s ai_c">
					    <el-radio :label="0" style="margin:0 40px 0 0">非连锁</el-radio>
						<el-radio :label="1">连锁</el-radio>
					  </el-radio-group>
				</el-form-item>
			</div>
			<div class="xgczMidm">
				<div class="flex jc_b">
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">20以内</span>
						<el-form-item>
							<el-input-number v-model="item.in20" style="width: 126px!important;"  placeholder="" controls-position="right"  class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">20-30</span>
						<el-form-item>
							<el-input-number v-model="item.from20to30"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">30-40</span>
						<el-form-item>
							<el-input-number v-model="item.from30to40"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
				</div><!---->
				<div class="flex jc_b pv20">
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">40-50</span>
						<el-form-item>
							<el-input-number  v-model="item.from40to50" style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">50-60</span>
						<el-form-item>
							<el-input-number  v-model="item.from50to60" style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">60-70</span>
						<el-form-item>
							<el-input-number  v-model="item.from60to70" style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
				</div><!---->
				<div class="flex jc_b pb15">
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">70-80</span>
						<el-form-item>
							<el-input-number  v-model="item.from70to80" style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">80-90</span>
						<el-form-item>
							<el-input-number v-model="item.from80to90"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
					<div class="flex jc_s ai_c">
						<span class="col999 f14 mr14">90以上</span>
						<el-form-item>
							<el-input-number v-model="item.out90"  style="width: 126px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
						</el-form-item>
					</div>
				</div><!---->
			</div><!--xgczMidm end-->
			<div class="flex jc_c ai_c pv45">
				<el-button type="primary" class="ssbtn" style="width: 5.375rem; margin-right: 5rem" @click="editConfirm">确认</el-button>
				<el-button class="cancelbtn" style="width: 5.375rem;" @click="bjxgDialog = false">取消</el-button>
			</div>
		</el-form></div><!--qxmid end-->
	</el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            valSelect: "",
			select:{
				dataType:'',
				cinameType:'',
				searchType:'',
				valSerch:''
			},
			selectOption:{
				dataOption:[
					{
						value: "2",
						label: "全部",
					},
					{
						value: "1",
						label: "旧数据",
					},
					{
						value: "0",
						label: "新数据",
					},
				],
				cinameOption:[
					{
						value: "2",
						label: "全部",
					},
					{
						value: "1",
						label: "连锁",
					},
					{
						value: "0",
						label: "非连锁",
					},
				],
				searchOption:[
					{
						value: "1",
						label: "影院",
					},
					{
						value: "2",
						label: "城市",
					},
					{
						value: "3",
						label: "地址",
					},
				]
			},
			// 表格数据
            tableData: [
                {
                    valtable1: "CGV影城（七宝万科广场IMAX店）",
                    valtable2: "上海",
                    valtable3: "闵行区漕宝路3366号上海七宝万科广场5层L501单元",
                    valtable4: "0.95",
                    valtable5: "0.95",
                    valtable6: "0.95",
                    valtable7: "0.95",
                    valtable8: "0.95",
                    valtable9: "0.95",
                    valtable10: "0.95",
                    valtable11: "0.95",
                    valtable12: "0.95",
                    valtable13: "老数据",
                    valtable14: "连锁",
                },
                {
                    valtable1: "CGV影城（七宝万科广场IMAX店）",
                    valtable2: "上海",
                    valtable3: "闵行区漕宝路3366号上海七宝万科广场5层L501单元",
                    valtable4: "0.95",
                    valtable5: "0.95",
                    valtable6: "0.95",
                    valtable7: "0.95",
                    valtable8: "0.95",
                    valtable9: "0.95",
                    valtable10: "0.95",
                    valtable11: "0.95",
                    valtable12: "0.95",
                    valtable13: "老数据",
                    valtable14: "连锁",
                },
                {
                    valtable1: "CGV影城（七宝万科广场IMAX店）",
                    valtable2: "上海",
                    valtable3: "闵行区漕宝路3366号上海七宝万科广场5层L501单元",
                    valtable4: "0.95",
                    valtable5: "0.95",
                    valtable6: "0.95",
                    valtable7: "0.95",
                    valtable8: "0.95",
                    valtable9: "0.95",
                    valtable10: "0.95",
                    valtable11: "0.95",
                    valtable12: "0.95",
                    valtable13: "老数据",
                    valtable14: "连锁",
                },
            ],
			// 搜索条件
			condition:{
				limit:20,
				offset:0,
				tp:'',
				is_old:'',
				nm:'',
				addr:'',
				cname:'',
			},
			item:'',
            pageSize: 20,
            currentPage: 1,
            total: 0,
            plczDialog: false,
            bjxgDialog: false,
			batch:{
				nm:'',
				tp:'',
				in20:'0.95',
				from20to30:'0.925',
				from30to40:'0.92',
				from40to50:'0.915',
				from50to60:'0.91',
				from60to70:'0.895',
				from70to80:'0.89',
				from80to90:' 0.885',
				out90:'0.88',
			}
        };
    }, //data
	created(){
		this.getData()
	},
    methods: {
		// 获取数据
		getData(val = 1){
			this.condition.offset = (val - 1) * 20
			if(this.condition.is_old){
				this.condition.is_old = Number(this.condition.is_old)
			}
			if(this.condition.tp){
				this.condition.tp = Number(this.condition.tp)
			}
			this.$api.getCinemaList(this.condition).then(res => {
				if(res.data.code == 0){
					let data = res.data.data
					this.total = data.count
					this.tableData = data.rows
					// console.log(this.tableData)
				}
			}).catch(err => {
				// console.log(err)
			})
		},
        handleCurrentChange(val) {
            this.currentPage = val;
			this.getData(val)
        },
		// 搜索按钮
		searchBtn(){
			this.currentPage = 1
			this.condition.tp = this.select.cinameType
			this.condition.is_old = this.select.dataType
			switch (Number(this.select.searchType)) {
				case 1:
					this.condition.cname = ''
					this.condition.addr = ''
					this.condition.nm = this.select.valSerch
					break;
				case 2:
					this.condition.nm = ''
					this.condition.addr = ''
					this.condition.cname = this.select.valSerch
					break;
				case 3:
					this.condition.nm = ''
					this.condition.cname = ''
					this.condition.addr = this.select.valSerch
					break;
				default:
					this.condition.nm = ''
					this.condition.cname = ''
					this.condition.addr = ''
					this.select.valSerch = ''
			}
			// console.log(this.condition)
			this.getData()
		},
		editBtn(obj){
			this.item = obj
			this.bjxgDialog = true
			// console.log(this.item)
		},
		// 编辑按钮
		editConfirm(){
			 this.$confirm('是否继续修改该配置?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					id:this.item.id,
					tp:this.item.tp,
					in20:this.item.in20,
					from20to30:this.item.from20to30,
					from30to40:this.item.from30to40,
					from40to50:this.item.from40to50,
					from50to60:this.item.from50to60,
					from60to70:this.item.from60to70,
					from70to80:this.item.from70to80,
					from80to90:this.item.from80to90,
					out90:this.item.out90,
				}
				this.$api.setCinemaList(data).then(res => {
					this.bjxgDialog = false
					// console.log(res.data)
					if(res.data.code == 0){
						this.$message({
							type:'success',
							message:'修改成功'
						})
						this.getData()
					}
				}).catch(err => {
					console.log(err)
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			})

		},
		// 批量操作按钮
		batchConfirm(){
				this.$confirm('是否继续修改该配置?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				if(this.batch.tp == 2){
					this.batch.tp = ''
				}
				this.$api.setSomeCinema(this.batch).then(res => {
					this.plczDialog = false
					if(res.data.code == 0){
						this.$message({
							type:'success',
							message:'修改成功'
						})
						this.getData()
					}
					}).catch(err => {
						console.log(err)
					})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			})
		}
    }, //methods
};
</script>

<style lang="less" scoped>
.el-form-item {
    margin-bottom: 0;
}
/deep/ .el-table_1_column_15  .cell{
	overflow: visible;
}
// /deep/ .el-input--suffix .el-input__inner {
// 	color: #409EFF !important;
// }
// /deep/ .el-input--suffix .el-input__inner{
// 	border-color: #ccc !important;
// }
</style>